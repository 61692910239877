.App {
  text-align: center;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.buttonVote {
  display: inline;
  border: 0;
  padding: 5px;
  margin: 10px;
  box-shadow: 0px 10px 14px -7px #276873;
  background:linear-gradient(to bottom, #599bb3 5%, #408c99 100%);
  background-color:#599bb3;
  border-radius:8px;
  color:#ffffff;
  font-size:20px;
  font-weight:bold;
  text-decoration:none;
  text-shadow:0px 1px 0px #3d768a;
}
.App-link {
  color: #61dafb;
}

